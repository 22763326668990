<template>
  <div class="home-index">
    <div class="home-login">
      <div class="w50 bg-i right-l-h2">
        <img
          class="img-login-logo"
          src="./../../../public/img/gohrnew.png"
        />
        <div class="call-number">
          <div class="dial-pad">
            <div class="dial-screen" style="font-size: 40px; line-height: 68px;" contenteditable="false">
              {{ hidden }}
            </div>
            <div class="dial-table">
              <div class="dial-table-row">
                <div class="dial-table-col">
                  <div class="dial-key-wrap" @click="append('1')">
                    <div class="dial-key">1</div>
                  </div>
                </div>
                <div class="dial-table-col">
                  <div class="dial-key-wrap" @click="append('2')">
                    <div class="dial-key">2</div>
                  </div>
                </div>
                <div class="dial-table-col">
                  <div class="dial-key-wrap" @click="append('3')">
                    <div class="dial-key">3</div>
                  </div>
                </div>
              </div>
              <div class="dial-table-row">
                <div class="dial-table-col">
                  <div class="dial-key-wrap" @click="append('4')">
                    <div class="dial-key">4</div>
                  </div>
                </div>
                <div class="dial-table-col">
                  <div class="dial-key-wrap" @click="append('5')">
                    <div class="dial-key">5</div>
                  </div>
                </div>
                <div class="dial-table-col">
                  <div class="dial-key-wrap" @click="append('6')">
                    <div class="dial-key">6</div>
                  </div>
                </div>
              </div>
              <div class="dial-table-row">
                <div class="dial-table-col">
                  <div class="dial-key-wrap" @click="append('7')">
                    <div class="dial-key">7</div>
                  </div>
                </div>
                <div class="dial-table-col">
                  <div class="dial-key-wrap" @click="append('8')">
                    <div class="dial-key">8</div>
                  </div>
                </div>
                <div class="dial-table-col">
                  <div class="dial-key-wrap" @click="append('9')">
                    <div class="dial-key">9</div>
                  </div>
                </div>
              </div>
              <div class="dial-table-row no-sub-key">
                <div class="dial-table-col w-48">
                  <div class="dial-key-wrap" @click="append('0')">
                    <div class="dial-key">0</div>
                  </div>
                </div>
                <div class="dial-table-col w-48">
                  <div class="dial-key-wrap" @click="del">
                    <div class="dial-key">
                      <i class="el-icon-back"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div class="dial-table-row no-sub-key">
                <div @click="login" class="dial-table-col w-100">
                  <!-- <router-link :to="{ name: 'Home' }"> -->
                  <div class="dial-key-wrap">
                    <div class="dial-key">
                      <i class="el-icon-unlock"></i>
                    </div>
                  </div>
                  <!-- </router-link> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="info-right2 ">
          <h2 style="font-size:32px">Parolingizni kiriting</h2>
        </div>
        <br />
        <br />
        <br />
        <br />
        <!-- <router-link class="lick-login2" :to="{ name: 'login' }">
          <div>
            <i class="el-icon-d-arrow-left"></i>
            QR-KOD ORQALI KIRISH
          </div>
        </router-link> -->

        <svg
          viewBox="0 0 960 540"
          width="100%"
          height="100%"

          preserveAspectRatio="xMidYMax slice"
          xmlns="http://www.w3.org/2000/svg"
          class="absolute inset-0 pointer-events-none ng-tns-c187-29"
          style="transform: rotateX(180deg); color:#135196"
        >
          <g
            fill="none"
            stroke="currentColor"
            stroke-width="100"
            class="text-gray-700 opacity-25 ng-tns-c187-29"
          >
            <circle r="234" cx="196" cy="23" class="ng-tns-c187-29"></circle>
            <circle r="234" cx="790" cy="491" class="ng-tns-c187-29"></circle>
          </g>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="600" height="700"
          class="absolute  pointer-events-none sircle-2"
          style="color:#135196">
          <circle cx="200" cy="310" r="300" fill="none" stroke="#135196" stroke-width="170"/>  
        </svg>
        <!-- <svg
          class="posSvg"
          viewBox="0 0 220 192"
          width="220"
          height="192"
          fill="none"
        >
          <defs class="ng-tns-c187-29">
            <pattern
              id="837c3e70-6c3a-44e6-8854-cc48c737b659"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
              class="ng-tns-c187-29"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                fill="currentColor"
                class="ng-tns-c187-29"
              ></rect>
            </pattern>
          </defs>
          <rect
            width="220"
            height="192"
            fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
            class="ng-tns-c187-29"
          ></rect>
        </svg> -->
        
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from 'moment';
import { setItem,getItem, removeItem } from "@/utils/storage";
export default {
  name: "login_auth",
  // components: { homeTop }
  data() {
    return {
      activeName: "callTab",
      izn_password: "",
      hidden: "",
      input2: "",
      activeIconPhone: false,
      callingModal: false,
      addMic: false,
      noneBlock: false,
      miniPage: false,
      noneIcon: false,
    };
  },
  methods: {
    ...mapActions({
      updateList: "assent/check",
    }),
    handleClose(done) {
      this.$confirm("Are you sure to close this dialog?")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },

    /****************Phone Numer***********************/
    append(value) {
      if (this.izn_password.length < 6 || this.izn_password == "") {
        this.izn_password += value.toString();
        this.hidden += "*";
      }
    },
    login() {
      if (this.izn_password.length == 6) {
        var params = {
          id:getItem('userIdTest'),
          password:this.izn_password,
        }
        this.updateList(params).then((res) => {
          console.log(res.status)
          if (res.status==200) {            
            setItem("userId", params.id);
            // setItem("password", params.password);
            let route = this.$router.push({
              name: "Home",
            });
            setItem('additional_work_reason_id', null);
            setItem('performance_type', null),
            setItem('work_time', 
                        [
                          moment().format('YYYY-MM-DD HH:mm:ss'), 
                          moment().format('YYYY-MM-DD HH:mm:ss')
                        ]);
            console.log(moment().format('YYYY-MM-DD HH:mm:ss'));
            this.izn_password = "";
            window.open(route.href, "_self");
            
           
          } 
          else{
            this.$alert(res);
          }
        });
      }
    },
    del() {
      if (this.izn_password.toString().length > 1) {
        this.izn_password = this.izn_password
          .toString()
          .substring(0, this.izn_password.toString().length - 1);
        this.hidden = this.hidden
          .toString()
          .substring(0, this.hidden.toString().length - 1);
      } else {
        this.izn_password = "";
        this.hidden = "";
      }
    },
    /****************End Phone Numer***********************/

    /****************icon Phone Icon Modal***********************/
    iconPhone(e) {
      this.activeIconPhone = true;
      this.noneIcon = true;
    },
    closeIconPhone(e) {
      this.activeIconPhone = false;
      this.noneIcon = false;
    },
  },
};
</script>
<style lang="css">
.home-login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  position: relative;
}
.sircle-2{
  top: -300px;
  left: 0;
}
.w50 {
  width: 100%;
  height: 100vh;
  position: relative;
}
.bg-i {
  background: #1e293b;
}
.bg-i svg {
  color: #2f3c50;
}
.posSvg {
  position: absolute;
  top: 1px;
  right: 1px;
}
.inset-0 {
  top: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
}
.absolute,
.\!absolute {
  position: absolute !important;
}
.right-l-h2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 10;
}
.info-right2 {
  z-index: 2;
  position: relative;
  /* padding: 20px; */
  color: #fff;
  /* font-family: Arial, Helvetica, sans-serif; */
}
.info-right2 h2 {
 
  font-family:"Arial";
}

.pin-code {
  padding: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.left-l-h {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 50px;
}
.pin-code input {
  border: none;
  text-align: center;
  width: 48px;
  height: 48px;
  font-size: 36px;
  background-color: #f3f3f3;
  margin-right: 5px;
}

.pin-code input:focus {
  border: 1px solid #573d8b;
  outline: none;
}

.pin-code input::-webkit-outer-spin-button,
.pin-code input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.call-number {
  position: relative;
  z-index: 10;
}

.call-number-kontak {
  display: flex;
}
.call-kontak {
  width: 100%;
}
.dial-pad {
  width: 300px;
}

/* .call-number {
    border-right: 1px solid #dddddd8c;
    padding-right: 20px;
    margin-right: 20px;
} */
.dial-screen {
  height: 50px;
  padding: 0 15px;
  font-size: 28px;
  font-weight: 400;
  line-height: 50px;
  text-align: center;
  margin-bottom: 10px;
  background: #eef1f229;
  color: #fff;
  letter-spacing: 15px;
}
.dial-table {
  width: 100%;
  display: table;
}
.dial-table .dial-table-row {
  display: flex;
  justify-content: space-between;
}
.dial-table .dial-table-col {
  cursor: default;
  width: 31%;
  min-height: 53px;
  text-align: center;
  display: inline-table;
  vertical-align: top;
  border: 1px solid #cbd5e1;
  margin: 1%;
  border-radius: 8px;
}
.dial-table .dial-key-wrap {
  /* margin-bottom: 10px; */
  transition: background 0.3s ease-in-out 0s;
}
.w-100 {
  width: 100% !important;
}
.w-48 {
  width: 47.5% !important;
}
.dial-table .dial-table-col .dial-key {
  font-size: 28px;
  font-weight: 400;
  min-height: 53px;
  line-height: 53px;
  cursor: pointer;
  color: #fff;
  user-select: none;
}
.dial-table .no-sub-key .dial-key {
  min-height: 53px;
  line-height: 53px;
}
.dial-table .dial-sub-key {
  color: #aaa;
  font-size: 14px;
  text-transform: uppercase;
}
.no-sub-key .dial-sub-key {
  display: none;
}
/* .dial-table .dial-key-wrap.active,
.dial-table .dial-key-wrap:hover {
    color: #eee;
    background: #16a085;
} */
.dial-table .dial-key-wrap.active .dial-sub-key,
.dial-table .dial-key-wrap:hover .dial-sub-key {
  color: #eee;
}
.btn-call {
  background: #38a3fd;
  border: 2px solid #38a3fd;
  border-radius: 50%;
  box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3);
  cursor: pointer;
  height: 60px;
  width: 60px;
  text-align: center;
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 999;
  transition: 0.3s;
  -webkit-animation: hoverWave linear 1s infinite;
  animation: hoverWave linear 1s infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.btn-call__ico {
  display: flex;
  justify-content: center;
  align-items: center;
  animation: 1200ms ease 0s normal none 1 running shake;
  animation-iteration-count: infinite;
  -webkit-animation: 1200ms ease 0s normal none 1 running shake;
  -webkit-animation-iteration-count: infinite;
  color: white;
  font-size: 30px;
  padding-top: 5px;
  transition: 0.3s all;
}

.btn-call:hover {
  background-color: #fff;
}

.btn-call:hover .btn-call__ico {
  color: #38a3fd;
}
.img-login-logo {
  width: 200px;
  position: absolute;
  top: 30px;
  z-index: 9;
  left: 50px;
}
.lick-login2 {
  /* background-color: #00c200; */
  font-weight: 300;
  border-radius: 5px;
  color: #fff;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  position: relative;
  text-transform: uppercase;
}
</style>
